<template>
  <div>
    <div class="text-black">
      <BCol
        md="12"
        class="d-flex gap-10 w-100 p-0 my-[24px]"
      >
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 340px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
          tbody-tr-class="hover:bg-slate-100 transition-colors duration-300 cursor-pointer"
          @row-clicked="(item, _, e) => $router.push({ name: $route.meta.detailTalentPool, params: { id: item.id } })"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(phone_number)="data">
            <div class="flex gap-5 items-center">
              <div
                class="cursor-pointer"
                @click="openWhatsapp(data.item.phone_number)"
              >
                {{ data.item.phone_number }}
                <span
                  class="k-Whatsapp h-100 text-[#34A853] font-medium text-12"
                />
              </div>
            </div>
          </template>
          <template #cell(is_follow_up)="data">
            <div
              v-if="data.item.is_follow_up"
              class="status px-[5px] py-[2px] rounded"
            >
              Followed
            </div>
            <span v-else>
              -
            </span>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{ name: $route.meta.detailTalentPool, params: { id: data.item.id } }"
                tag="router-link"
              >
                <span
                  class="k-eye h-100 text-[#08A0F7] font-medium text-12"
                />
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ModalDelete
      :id-item="idItem"
      @deleted="getListData()"
    />
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { tableTalentPool } from '../config'
import ModalDelete from '../ModalDelete.vue'

export default {
  components: {
    ModalDelete,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableTalentPool,
      idItem: 0,
      alertError,
    }
  },
  mounted() {
    this.getListData()
    this.$store.dispatch('talentPool/getTotal')
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}`
      const url = `v1/talent_pool/user/list?${params}`
      //   const swaggerKomtim =
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}`
        const url = `v1/talent_pool/user/list?${params}`

        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.filterSelected = []
      this.getListData()
    },
    openWhatsapp(phone) {
      const url = `https://wa.me/${phone}`
      window.open(url, '_blank')
    },
  },

}
</script>

<style scoped>
.status {
  background: #DCF3EB;
  color: #34A770;
}
</style>
