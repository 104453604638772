<template>
  <div>
    <div class="text-black">
      <BCol
        md="12"
        class="d-flex gap-10 w-100 p-0 my-[24px]"
      >
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
        <BButton
          tag="router-link"
          :to="{
            name: $route.meta.routeCreate,
          }"
          variant="primary"
          class="ml-auto px-3"
        >
          <strong>Tambah</strong>
        </BButton>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 340px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(mou)="data">
            <span
              :class="data.item.mou ? 'text-[#08A0F7] cursor-pointer' : 'text-[#333]'"
              @click="data.item.mou ? toLink(data.item.mou) : false"
            >{{ data.item.mou ? 'Detail' : '-' }}</span>
          </template>
          <template #cell(name)="data">
            <div class="flex items-start flex-col gap-4">
              <div>
                {{ data.item.name }}
              </div>
              <div class="text-muted">
                {{ data.item.no_partner }}
              </div>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                >
              </BButton>
              <div
                v-b-modal.pauseConfirmation
                @click="selectItem(data.item.id)"
              >
                <BFormCheckbox
                  disabled
                  :checked="true"
                  switch
                />
              </div>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <PauseConfirmation
      :id-item="idItem"
      :pause="true"
      @updated="getListData()"
    />
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { tableActive } from '../config'
import PauseConfirmation from '../PauseConfirmation.vue'

export default {
  components: {
    PauseConfirmation,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableActive,
      idItem: 0,
      alertError,
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&status=active&keyword=${this.keyword}`
      const url = `v1/partners?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=active&keyword=${this.keyword}`
        const url = `v1/partners?${params}`

        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    toLink(link) {
      window.open(link, '_blank')
    },
    resetFilter() {
      this.filterSelected = []
      this.getListData()
    },
  },

}
</script>
